<template>
  <v-snackbar
    :value="show"
    top
    right
    absolute
    :timeout="time"
    min-width="200"
    :color="type"
  >
    {{ msg }}
  </v-snackbar>
</template>

<script>
export default {
  props: ["show", "msg", "time", "type"],
};
</script>