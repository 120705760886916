<template>
  <v-overlay opacity="1" :value="loading" absolute>
    <v-card>
      <v-card-text class="text-center">
        <v-progress-circular indeterminate color="grey" />
        <p class="mb-0 mt-3">{{ msg }}</p>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props: ["loading", "msg"],
};
</script>