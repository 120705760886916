const rulesUser = {
    email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail no válido.";
    },
    count: (v) => (v && v.length >= 6) || "Mínimo 6 caracteres",
    required: (value) => !!value || "Obligatorio.",
}

const rules = {
    required: (value) => !!value || "Obligatorio.",
}

export { rules, rulesUser }